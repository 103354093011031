


/* CustomCursor.css */

.cursorDot{
    top: 0;
    left: 0;
    width: 5px;
    height: 5px;
    background: white;
    border-radius: 50%;
    position: fixed;
    pointer-events: none;
    transform: translate(-50%, -50%);
    z-index: 999;
  }
  
  .cursorOutline {
    top: 0;
    left: 0;
    width: 30px;
    height: 30px;
    border: 2px solid #64CCC5;
    border-radius: 50%;
    position: fixed;
    pointer-events: none;
    transform: translate(-50%, -50%);
    z-index: 999;
  }

  @media only screen and (max-width: 1024px) {
    .cursorOutline{
      width: 0;
      border: none;
    }
  }
 