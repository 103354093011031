
.banner{
    position: relative;
    width: 100%;
    height: 50vh;
    background: url('https://www.ashlingpartners.com/wp-content/uploads/2022/10/Which-Processes-Can-Be-Automated-Using-RPA.jpeg');
    background-size: cover;
    background-position: right;
    background-attachment: fixed;
    border-radius: 20px;
}
.section{
    position: relative;
    height: auto;
    display: flex;
}
.section:nth-child(2n+1){
    position: relative;
    height: auto;
    display: flex;
    flex-direction: row-reverse;
}

.imgBx{
    position: sticky;
    top: 0;
    min-width: 50%;
    height: 100vh;
}
.img{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    min-width: 600px;
}
.content{
    margin: 300px auto 0;
}
@media only screen and (max-width: 1023px) {
    .section{
        display: flex;
        flex-direction: column;

    }
    .section:nth-child(2n+1){
        display: flex;
        flex-direction: column;
    }
    .imgBx{
        display: none;
    }
    .content{
        margin: 0 auto;
    }
  }

.card{
    background-size: cover;
    background-position: center;
    overflow: hidden;
    height: 150px;
}
.card1{
    background-image: url('../../../public/images/rpa/3.jpg');
}
.card2{
    background-image: url('../../../public/images/rpa/4.jpg');
}
.card3{
    background-image: url('../../../public/images/rpa/1.jpg');
}
.card4{
    background-image: url('../../../public/images/rpa/2.jpg');
}
.card5{
    background-image: url('../../../public/images/rpa/5.jpg');
}
.span{
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0,0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}
@media only screen and (max-width: 640px) {
    .span{
        font-size: 10px;
    }
    .card{
        height: 100px;
    }
  }
