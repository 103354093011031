
.section{
    position: relative;
    height: auto;
    display: flex;
}
.section:nth-child(2n+1){
    position: relative;
    height: auto;
    display: flex;
    flex-direction: row-reverse;
}

.imgBx{
    position: sticky;
    top: 0;
    min-width: 50%;
    height: 60vh;
}
.img{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    min-width: 600px;
    margin-top: 100px;
}
.content{
    margin: 200px auto 0;
}
@media only screen and (max-width: 1023px) {
    .section{
        display: flex;
        flex-direction: column;
    }
    .section:nth-child(1n+2){
        display: flex;
        flex-direction: column;
    }
    .imgBx{
        display: none;
    }
    .content{
        margin: 0 auto;
    }
  }