
.img{
    background-image: url('../../../public/images/myImage/background.jpg');
    /* background-image: url('https://static.independent.co.uk/2023/06/21/13/Oktra_Header%20Image_iStock-1434119140.jpg'); */
    height: 50vh;
    width: 100%;
    background-size: cover;
    object-fit: cover;
    background-attachment: fixed;
    background-position: center;
}
.multiple{
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0,.5);
}
