/* .galleryChild {
	overflow: hidden;
}

.galleryImg {
	transform: scale(1);
	transition: transform .3s ease-in-out;
}

.gallery .galleryImg:hover {
	transform: scale(1.2);
}
 */





/* .image_grid {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  align-items: center;
  justify-content: center;
  max-width: 80%;
  height: 100%;
  margin: 0 auto;
  padding: 0;
  list-style: none;
} */

.image_grid__item {
  display: flex;
  flex-direction: column;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 360px;
  align-items: stretch;
  justify-content: center;
  position: relative;
  height: 220px;
  margin-right: 14px;
  margin-bottom: 14px;
  transition: transform 0.14s ease-in, text-shadow 0.1s ease-in;
}

.image_grid__item:before {
  content: '';
  visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: none;
  border-radius: 3px;
  box-shadow: 0 10px 24px 0px rgba(0, 0, 0, 0.06), 0 8px 20px -2px rgba(0, 0, 0, 0.10), 0 6px 10px -6px rgba(0, 0, 0, 0.20);
  transition: visibility 0.1s ease-out, opacity 0.1s ease-out;
  opacity: 0;
}

/* .image_grid__item:hover {
  .image_grid__item:before {
    visibility: visible;
    opacity: 1;
  }
} */

.grid_item {
  display: flex;
  position: relative;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 1;
  align-items: stretch;
  justify-content: center;
  text-decoration: none;
  color: #eeeeee;
  overflow: hidden;
}

.grid_item:hover .grid_item__image {
    transform: scale(1.2);

  /* .grid_item__hover {
    visibility: visible;
    opacity: 1;
  }

  .grid_item__name {
    visibility: visible;
    transform: scale(1);
    opacity: 1;
  } */
}
.grid_item:hover .grid-item__image{
    transform: scale(1.2);
}
.grid_item__image {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-size: cover;
  background-position: center center;
  transform: scale(1);
  will-change: transform;
  transition: transform 0.35s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  z-index: 0;
}
.grid_item__image1 {
background-image: url('../../../public/images/layoutImage/1.jpg');
}
.grid_item__image2 {
background-image: url('../../../public/images/layoutImage/2.jpg');
}
.grid_item__image3 {
background-image: url('../../../public/images/layoutImage/3.jpg');
}
.grid_item__image4 {
background-image: url('../../../public/images/layoutImage/4.jpg');
}
.grid_item__image5 {
background-image: url('../../../public/images/layoutImage/5.jpg');
}
.grid_item__image6 {
background-image: url('../../../public/images/layoutImage/6.jpg');
}
.grid_item__image7 {
background-image: url('../../../public/images/layoutImage/7.jpg');
}
.grid_item__image8 {
background-image: url('../../../public/images/layoutImage/8.jpg');
}
.grid_item__image9 {
background-image: url('../../../public/images/layoutImage/9.jpg');
}

.grid_item__hover {
  /* visibility: hidden; */
  visibility: visible;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: radial-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.3));
  box-shadow: inset 0 0 50px rgba(0, 0, 0, 0.01);
  border-radius: 0;
  transition: visibility 0.35s cubic-bezier(0.25, 0.46, 0.45, 0.94), opacity 0.35s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  opacity: 1;
}

.grid_item__name {
  /* visibility: hidden; */
  visibility: visible;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 1;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 1rem;
  font-weight: 700;
  text-shadow: 10px 30px 30px rgba(0, 0, 0), 30px 30px 30px rgba(0, 0, 0);
  justify-content: center;
  letter-spacing: 1px;
  transform: scale(1);
  transform-origin: center center;
  transition: visibility 0.14s ease-out, opacity 0.14s ease-out, transform 0.24s ease;
  opacity: 1;
}
@media only screen and (max-width: 1000px) {
  .grid_item__name {
   font-size: 10px;
   font-weight: 600;
  }
  .image_grid__item {
    max-height: 150px;
  }
}
@media only screen and (max-width: 450px) {
  .image_grid__item {
    max-height: 100px;
  }
}
