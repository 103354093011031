
.logo{
    background-position: center;
    background-size: cover;
    max-height: 100px;
    padding: 20px;
    margin: 0 auto;
}
.park{
    background-position: center;
    background-size: cover;
    max-height: 130px;
    margin: 0 auto;
}
@media only screen and (max-width: 600px) {
    .logo {
        max-height: 70px;
    }
    .park{
        max-height: 110px;
    }
  }