@tailwind base;
@tailwind components;
@tailwind utilities;

*,
body,
html {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  /* transition: all .5s; */
  cursor: none;
  font-family: Arial, Helvetica, sans-serif;
  scroll-behavior: smooth;
}
@media only screen and (max-width: 1024px) {
  * {
    cursor: default;
  }
}
.threeModel {
  background-image: url("/public/images/HomeImage.jpg");
  /* background-color: #8083c9; */
  background-position: center;
  object-fit: cover;
  background-repeat: no-repeat;
  background-size: cover;
}

/* .swiper-button-prev{
  height: 20px !important;
  overflow: hidden;
  color: #1AAB96 !important;
 }
 .swiper-button-next{
  height: 20px !important;
  overflow: hidden;
  color: #1AAB96 !important;
 } */

.updownAnimation {
  animation-name: infiniteUpDown;
  animation-duration: 4s;
  animation-iteration-count: infinite;
}
@keyframes infiniteUpDown {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-30px);
  }
  100% {
    transform: translateY(0px);
  }
}
