/* * {
    box-sizing: border-box;
  }
  
  body {
    min-height: 100vh;
  } */
  /* title garj ngaa heseg start*/
  .title {
    backdrop-filter: blur(5px);
    border: 1px solid rgb(255, 255, 255);
    border-radius: 5px;
    padding: 15px 25px;
    color: #000000;
  }
  @media screen and (max-width: 992px) {
    .title{
      font-size: 10px;
    }
  }
  /* title garj ngaa heseg end*/
  .body {
    --space: 200px;
  
    display: grid;
    align-content: center;
    overflow: hidden;
    gap: var(--space);
    width: 100%;
    font-size: 1.5rem;
    line-height: 1.5;
    /* background-color: #F8DC81; */
  }
  
  .marquee {
    --duration: 100s;
    --gap: var(--space);
    display: flex;
    overflow: hidden;
    user-select: none;
    gap: var(--gap);
  }
  
  .marquee__group {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: space-around;
    gap: var(--gap);
    min-width: 100%;
    animation: scroll var(--duration) linear infinite;
  }
  
  @media (prefers-reduced-motion: reduce) {
    .marquee__group {
      animation-play-state: paused;
    }
  }
  
  .marquee__group h4 {
    aspect-ratio: 1;
    object-fit: cover;
    border-radius: 1rem;
    border: 1px solid #ccc;
    padding: 3rem;
  }
  
  @keyframes scroll {
    0% {
      transform: translateX(0);
    }
  
    
    60% {
      transform: translateX(calc(-60% - var(--gap)));
    }
  }
  
  /* * {
      box-sizing: border-box;
    }
    
    body {
      min-height: 100vh;
    } */
  
  .body2 {
    --space: 3rem;
    margin-top: 20px;
    display: grid;
    align-content: center;
    overflow: hidden;
    gap: var(--space);
    width: 100%;
    font-size: 1.5rem;
    line-height: 1.5;
    /* background-color: #f8dc81; */
  }
  
  .marquee2 {
    --duration: 100s;
    --gap: var(--space);
  
    display: flex;
    overflow: hidden;
    user-select: none;
    gap: var(--gap);
  }
  
  .marquee__group2 {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: space-around;
    gap: var(--gap);
    min-width: 100%;
    animation: scroll2 var(--duration) linear infinite;
  }
  
  @media (prefers-reduced-motion: reduce) {
    .marquee__group2 {
      animation-play-state: paused;
    }
  }
  
  .marquee__group2 h4 {
    aspect-ratio: 1;
    object-fit: cover;
    border-radius: 1rem;
    border: 1px solid #ccc;
    padding: 3rem;
  }
  
  @keyframes scroll2 {
    0% {
      transform: translateX(calc(-100% - var(--gap)));
    }
  
    100% {
      transform: translateX(0);
    }
  }

  @media screen and (max-width: 1000px) {
    .body {
      --space: 80px;
    }
    @keyframes scroll {
      0% {
        transform: translateX(0);
      }
    
      
      60% {
        transform: translateX(calc(-70% - var(--gap)));
      }
    }
  }
  @media screen and (max-width: 640px) {
    .body {
      --space: 80px;
    }
    @keyframes scroll {
      0% {
        transform: translateX(0);
      }
    
      
      60% {
        transform: translateX(calc(-80% - var(--gap)));
      }
    }
  }