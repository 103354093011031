
.banner {
    position: relative;
    background-size: cover;
  }
  .sticky {
    position: fixed;
    background-color: #fff;
    transition: all 0.5s;
  }
  .text{
    color: black;
  }

  .scrollDown{
 
  -webkit-animation: bounce 1.5s infinite 1.5s;
  animation: bounce 1.5s infinite 1.5s;
  -webkit-transition: all .3s ease-in;
  transition: all .3s ease-in;
}

@keyframes bounce {
  0%,
  100%,
  20%,
  50%,
  80% {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
  40% {
    -webkit-transform: translateY(-10px);
    -ms-transform: translateY(-10px);
    transform: translateY(-10px);
  }
  60% {
    -webkit-transform: translateY(-5px);
    -ms-transform: translateY(-5px);
    transform: translateY(-5px);
  }
}