
.card{
    overflow: hidden;
}

.card:nth-child(2n+2){
background-color: #64ccc6;
margin-top: 50px;
color: #fff;
}
.iconEven {
    color: #64ccc6;
  }
  
  .iconOdd {
    color: #fff; /* Set your alternate color */
  }
@media screen and (max-width: 1023px) {
    .card:nth-child(2n+2){
        margin-top: 0;
        }
  }
/* @media screen and (max-width: 1024px) {
    .card:nth-child(4n){
        background-color: inherit;
        color: #000;
    }
    .card:nth-child(3n){
        background-color: #64ccc6;
        color: #fff;
    }
  }
@media screen and (max-width: 768px) {
    .card:nth-child(4n){
        background-color: #64ccc6;
        color: #fff;
    }
    .card:nth-child(3n){
        background-color: inherit;
        color: #000;
    }
  } */